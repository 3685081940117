import { is_empty } from "./validations";
import dataProvider from "../data/dataProvider";
import moment from "moment";
import api from "../data/APIs";
import { RESOURCE_KEYS } from "./OrgPermissions";
import constants from "../constants/constants";
import { appendUrlParams } from "./urlUtils";
import DefaultAvatarSrc from "assets/images/default-user.png";
import SessionStorageConstants from "constants/SessionStorage.constants";
import local_storage_keys from "constants/LocalStorage.constants";
import posthog from "posthog-js";
import { identifyUser } from "authProvider";
import { app_pathnames, app_route_keys } from "constants/urlPaths";
import { isRequestSuccessful } from "./Utils";
import { fetchCreatorConfig } from "features/Common/modules/CreatorData/utils/CreatorData.utils";
import { apiMethods } from "data/api.constants";
import { WHATS_NEXT_APIS } from "features/Onboarding/modules/WhatsNext/WhatsNext.api";
import { FEATURE_ACTIVATION_APIS } from "features/FeatureActivationStatus/FeatureActivation.api";
import { logError } from "./error";
import { SESSION_STORAGE_KEYS } from "features/Common/modules/Storage/modules/SessionStorage/constants/SessionStorage.constant";
import { LOCAL_STORAGE_KEYS } from "features/Common/modules/Storage/modules/LocalStorage/constants/LocalStorage.constants";
import { setLocalStorageItem } from "features/Common/modules/Storage/modules/LocalStorage/utils/LocalStorage.utils";
import { getWhatsNextInfo } from "features/Onboarding/modules/WhatsNext/utils/whatsNext.utils";

//@DEV TODO : the functions from this file to be moved to src/features/Account/utils
function getUserID() {
  return localStorage.getItem("uuid");
}

export function removeUserID() {
  localStorage.removeItem("uuid");
}

function setUuid(uuid) {
  if (is_empty(uuid)) return;
  localStorage.setItem("uuid", uuid);
}

function setTokenUuid(token_uuid) {
  if (is_empty(token_uuid)) return;
  localStorage.setItem("token_uuid", token_uuid);
}

function getTokenUuid() {
  localStorage.getItem("token_uuid");
}

function setAuthData(auth, set_is_staff = false) {
  if (is_empty(auth)) return;
  if (set_is_staff && auth.is_staff) {
    localStorage.setItem("is_staff", `${moment().unix()}`);
  }
  localStorage.setItem("auth", JSON.stringify(auth));
  setUuid(auth.username);
  getRolePowers();
  getSubscriptionPermission();
}

function getAuthData() {
  let auth = localStorage.getItem("auth");
  if (is_empty(auth)) return "";
  auth = JSON.parse(auth);
  if (
    is_empty(auth.timezone) ||
    is_empty(auth.creator_currency) ||
    is_empty(auth.country_code)
  ) {
    if (is_empty(auth.timezone)) {
      auth.timezone = constants.indian_timezone;
    }
    if (is_empty(auth.creator_currency)) {
      auth.creator_currency = constants.inr;
    }
    if (is_empty(auth.country_code)) {
      auth.country_code = constants.indian_country_code;
    }
    localStorage.setItem("auth", JSON.stringify(auth));
  }
  return auth;
}

function getUserTimezone() {
  let auth = getAuthData();
  if (is_empty(auth) || is_empty(auth.timezone)) return "";
  return auth.timezone;
}

function getUserLogoImage() {
  let auth = getAuthData();
  if (
    is_empty(auth) ||
    is_empty(auth.social_info) ||
    is_empty(auth.social_info.logo_image) ||
    auth.social_info.logo_image === constants.null_display_image
  )
    return DefaultAvatarSrc;
  return auth.social_info.logo_image;
}

function isInternationalTimezone() {
  return getUserTimezone() !== constants.indian_timezone;
}

function getUserCurrency() {
  let auth = getAuthData();
  if (is_empty(auth)) return "";
  return auth.creator_currency;
}

function getUserPhoneNumber() {
  let auth = getAuthData();
  if (is_empty(auth)) return "";
  return auth.country_code.concat(auth.phone_number);
}

function isInternationalCurrency() {
  return getUserCurrency() === constants.usd;
}

function getUserCurrencySymbol(currency_symbol = null) {
  if (!is_empty(currency_symbol)) return currency_symbol;
  return isInternationalCurrency()
    ? constants.usd_currency
    : constants.inr_currency;
}

function getUserCountry() {
  let auth = getAuthData();
  if (is_empty(auth)) return "";
  return auth.country;
}

function getUserCountryCode() {
  let auth = getAuthData();
  if (is_empty(auth)) return "";
  return auth.country_code;
}

function isInternationalCountryCode() {
  return getUserCountryCode() !== constants.indian_country_code;
}
/**
 * Function to check if we need to refetch the user details which
 * were updated by creator using different session.
 */
const needToRefetchUserAuthDetails = (lastProfileUpdate) => {
  const { last_profile_update: localStorageLastProfileUpdate } =
    getWhatsNextInfo();

  // Checks if latest lastUpdatedAt is not undefined & not equals to one present in local storage.
  return (
    lastProfileUpdate && lastProfileUpdate != localStorageLastProfileUpdate
  );
};

async function fetchAndUpdateAuth() {
  // TODO @Dhruva: fix the below issue
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const userId = getUserID();
    try {
      const data = await dataProvider.custom_request(
        `${api.get_creator_page_details}/${userId}`
      );
      if (data && data.data && !is_empty(data.data)) {
        setAuthData(data.data, true);
        identifyUser(userId); // works while signup and login as mainUser
        return resolve(data);
      } else {
        return reject(false);
      }
    } catch (err) {
      console.error("fetchAndUpdateAuth err: ", err);
      return reject(err);
    }
  });
}

function onUserLogout(next = false) {
  let hash = window.location.hash;
  let location = window.location.href;
  location = location.replace("#/", "");

  const url = new URL(location);
  localStorage.clear();
  sessionStorage.clear();
  posthog.reset(); // reset user identity on posthog during logout.
  if (next) {
    sessionStorage.setItem("next", hash);
  }

  setUtmDetails(url);
  window.location.href =
    window.location.href.indexOf("signup") > -1 ? window.location.href : "/#";
  // TODO: currently we just reloaded it on Logout, but as of now since react-admin version doesn't support useResetStore() hook.
  // https://marmelab.com/react-admin/useResetStore.html#example
  window.location.reload();
}

function checkStaffLogin(email) {
  const regexEmail = RegExp(/^([\w.%+-]+[a-zA-Z0-9])@([\w-]+\.)+([\w]{2,})$/i);
  if (!email.includes(";")) return "";
  let ids = email.split(";");
  if (
    !regexEmail.test(ids[0].trim()) ||
    !regexEmail.test(ids[1].trim()) ||
    !ids[1].includes("@myscoot.in")
  )
    return "invalid";
  return {
    id: ids[0],
    bd_email: ids[1],
  };
}
async function hitAnalytic(type, bd_email) {
  try {
    if (is_empty(type)) {
      await dataProvider.postAnalyticalEvent("creatorPage", "creatorLogin", {
        flow: "creatorPage",
        funnel: "creatorPage",
      });
    } else {
      localStorage.setItem("bdLogin", "true");
      await dataProvider.postAnalyticalEvent("creatorPage", "bdLogin", {
        flow: "creatorPage",
        funnel: "creatorPage",
        user_meta: { username: bd_email },
      });
      bd_email = "";
    }
  } catch (err) {
    console.error(err);
    if (!is_empty(err.body) && !is_empty(err.body.message)) {
      alert(err.body.message);
    }
  }
}

function setUtmDetails(location) {
  let urlParams = "";
  let utm_details = "";
  if (!is_empty(location.search)) {
    let queryString = "";
    queryString = location.search;
    urlParams = new URLSearchParams(queryString);
    utm_details = {
      source: !is_empty(urlParams)
        ? urlParams.get("utm_source") || urlParams.get("tm_utm_source")
        : "",
      medium: !is_empty(urlParams)
        ? urlParams.get("utm_medium") || urlParams.get("tm_utm_medium")
        : "",
      campaign: !is_empty(urlParams)
        ? urlParams.get("utm_campaign") || urlParams.get("tm_utm_campaign")
        : "",
      spoc: !is_empty(urlParams)
        ? urlParams.get("utm_spoc") || urlParams.get("tm_utm_spoc")
        : "",
      brand: !is_empty(urlParams) ? urlParams.get("utm_brand") : "",
      affiliate: !is_empty(urlParams) ? urlParams.get("utm_affiliate") : "",
      tap_s: !is_empty(urlParams) ? urlParams.get("tap_s") : "",
      ref: !is_empty(urlParams) ? urlParams.get("ref") : "",
    };
    window.sessionStorage.setItem("utm_details", JSON.stringify(utm_details));
  }
}

function getUtmDetails() {
  const utmDetails =
    typeof window !== "undefined" &&
    window.sessionStorage.getItem("utm_details")
      ? JSON.parse(window.sessionStorage.getItem("utm_details"))
      : "";
  const utm_info = {
    source:
      !is_empty(utmDetails) && !is_empty(utmDetails.source)
        ? utmDetails.source
        : "",
    medium:
      !is_empty(utmDetails) && !is_empty(utmDetails.medium)
        ? utmDetails.medium
        : "",
    campaign:
      !is_empty(utmDetails) && !is_empty(utmDetails.campaign)
        ? utmDetails.campaign
        : "",
    spoc:
      !is_empty(utmDetails) && !is_empty(utmDetails.spoc)
        ? utmDetails.spoc
        : "",
    brand:
      !is_empty(utmDetails) && !is_empty(utmDetails.brand)
        ? utmDetails.brand
        : "",
    affiliate:
      !is_empty(utmDetails) && !is_empty(utmDetails.affiliate)
        ? utmDetails.affiliate
        : "",
    tap_source:
      !is_empty(utmDetails) && !is_empty(utmDetails.tap_s)
        ? utmDetails.tap_s
        : "",
    tap_ref:
      !is_empty(utmDetails) && !is_empty(utmDetails.ref) ? utmDetails.ref : "",
  };
  return utm_info;
}

function checkEmptyUTM() {
  const utmDetails =
    typeof window !== "undefined" &&
    window.sessionStorage.getItem("utm_details")
      ? JSON.parse(window.sessionStorage.getItem("utm_details"))
      : "";

  const isEmptyUTM = Object.values(utmDetails).every((item) => {
    if (is_empty(item)) {
      return true;
    }
    return false;
  });
  return isEmptyUTM;
}

async function sendOtp(values) {
  const email = values.email ? values.email : values.emailId;
  const isSignUp = values.isSignUp ? values.isSignUp : false;
  const phone_number = values.phone_number ? values.phone_number : "";
  const country_code = values.country_code ? values.country_code : "";
  const name = values.name ? values.name : "";
  const params = { email, isSignUp, phone_number, country_code, name };
  const data = await dataProvider.sendOtp(params);
  return data;
}

async function getPermissions({ forceCall } = {}) {
  // TODO @Dhruva: fix the below issue
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      let permission;
      if (!is_empty(sessionStorage.getItem("menu_permissions")) && !forceCall) {
        permission = JSON.parse(sessionStorage.getItem("menu_permissions"));
      } else {
        permission = await dataProvider.custom_request(api.menu_permissions);
        if (permission?.status === 200 && !is_empty(permission.data)) {
          permission = permission.data;
          setPermissions(permission);
        } else {
          return reject(false);
        }
      }
      return resolve(permission);
    } catch (err) {
      console.error("getPermissions err: ", err);
      return reject(err);
    }
  });
}

function setPermissions(permissions) {
  sessionStorage.setItem("menu_permissions", JSON.stringify(permissions));
}

function setSubscriptionPermission(permissions) {
  sessionStorage.setItem(
    "subscription_permission",
    JSON.stringify(permissions)
  );
}

async function getSubscriptionPermission({ forceCall } = {}) {
  // TODO: fix the below issue
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      let permission;
      const storedPermissions = JSON.parse(
        sessionStorage.getItem(SessionStorageConstants.SUBSCRIPTION_PERMISSION)
      );
      if (!is_empty(storedPermissions) && !forceCall) {
        permission = storedPermissions;
      } else {
        let permission = await dataProvider.custom_request(
          api.creator_subscription.get_subscription_feature_mapping
        );
        if (permission?.status === 200 && !is_empty(permission?.data)) {
          permission = permission?.data;
          setSubscriptionPermission(permission);
        } else {
          return reject(false);
        }
      }
      return resolve(permission);
    } catch (err) {
      console.error("getSubscriptionPermission err: ", err);
      return reject(err);
    }
  });
}

async function getRolePowers() {
  // TODO @Dhruva: fix the below issue
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      let role_powers;
      if (!is_empty(sessionStorage.getItem(SESSION_STORAGE_KEYS.ROLE_POWERS))) {
        role_powers = JSON.parse(
          sessionStorage.getItem(SESSION_STORAGE_KEYS.ROLE_POWERS)
        );
      } else {
        role_powers = await dataProvider.custom_request(
          api.get_role_description
        );
        if (
          role_powers &&
          role_powers?.status === 200 &&
          !is_empty(role_powers?.data)
        ) {
          role_powers = role_powers.data.role_data;
          sessionStorage.setItem(
            SESSION_STORAGE_KEYS.ROLE_POWERS,
            JSON.stringify(role_powers)
          );
        } else {
          return reject(false);
        }
      }
      return resolve(role_powers);
    } catch (err) {
      console.error("role_powers err: ", err);
      return reject(err);
    }
  });
}

async function getMvpleadData() {
  // TODO @Dhruva: fix the below issue
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      let mvplead_data;
      if (!is_empty(sessionStorage.getItem("mvplead_data"))) {
        mvplead_data = JSON.parse(sessionStorage.getItem("mvplead_data"));
      } else {
        mvplead_data = await dataProvider.custom_request(api.get_mavplead_data);
        if (
          mvplead_data &&
          mvplead_data?.status === 200 &&
          !is_empty(mvplead_data?.data)
        ) {
          mvplead_data = mvplead_data.data;
          sessionStorage.setItem("mvplead_data", JSON.stringify(mvplead_data));
        } else {
          return reject(false);
        }
      }
      return resolve(mvplead_data);
    } catch (err) {
      console.error("mvplead_data err: ", err);
      return reject(err);
    }
  });
}

function checkUserData() {
  return (
    !is_empty(sessionStorage.getItem(SESSION_STORAGE_KEYS.ROLE_POWERS)) &&
    !is_empty(sessionStorage.getItem(SESSION_STORAGE_KEYS.USER_CHECKS)) &&
    !is_empty(sessionStorage.getItem(SESSION_STORAGE_KEYS.CREATOR_CONFIG)) &&
    !is_empty(sessionStorage.getItem(SESSION_STORAGE_KEYS.MENU_PERMISSIONS))
  );
}

async function fetchUserData() {
  // TODO @Dhruva: fix the below issue
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      if (!is_empty(getAuthData())) {
        await getRolePowers();
        await getUserChecks();
        await getPermissions();
        await fetchCreatorConfig();
        await getSubscriptionPermission({ forceCall: true });
      }
      return resolve(true);
    } catch (err) {
      console.error("fetchUserData err: ", err);
      return reject(err);
    }
  });
}

const handleRedirectToNextParam = ({ userId }) => {
  let auth = getAuthData();
  let urlSearch = window.location.search;
  if (
    !urlSearch &&
    window.location.href.includes(window.location.origin + "/#/?next=")
  ) {
    urlSearch = window.location.hash.replace("#/", "");
  }

  const urlParams = new URLSearchParams(urlSearch);
  let nextUrl = urlParams.get("next");
  if (nextUrl) {
    nextUrl = decodeURIComponent(nextUrl);
    if (nextUrl.includes(process.env.REACT_APP_EXLY_CON_DOMAIN)) {
      let activeExlyConnectTab = sessionStorage.getItem(
        SessionStorageConstants.ACTIVE_EXLY_CONNECTED_TAB
      );

      nextUrl = appendUrlParams(nextUrl, {
        userId,
        from: encodeURIComponent(
          `${window.location.origin}/#/${app_pathnames[
            app_route_keys.exly_connect
          ](activeExlyConnectTab)}`
        ),
        userEmail: auth.email,
        userName:
          auth.first_name + (auth.last_name ? " " + auth.last_name : ""),
      });
    }
    window.location.href = nextUrl;
    return true;
  } else if (sessionStorage.getItem("next")) {
    const next = sessionStorage.getItem("next");
    sessionStorage.removeItem("next");
    window.location.replace(`${window.location.origin}/${next}`);
    return true;
  }
  return false;
};

async function handleLoginRedirect(utmParams = "") {
  try {
    const userId = getUserID();
    const isLoginFromMobile = !(window.screen.width > constants.mobile_width);
    if (!is_empty(userId)) {
      await fetchUserData();
      identifyUser(userId); // works while bdLogin
      const menu_permissions = await getPermissions();

      if (!is_empty(menu_permissions)) {
        const redirected = handleRedirectToNextParam({ userId });
        if (redirected) return;

        let permissions = menu_permissions.hidden_items;

        if (isLoginFromMobile) {
          window.location.replace(
            `${window.location.origin}/#/mobile-home${utmParams}`
          );
        } else if (!permissions[RESOURCE_KEYS.SECTIONS.DASHBOARD]) {
          window.location.replace(
            `${window.location.origin}/#/Dashboard${utmParams}`
          );
        } else if (!permissions?.create_edit_listing) {
          window.location.replace(
            `${window.location.origin}/#/ScheduleList${utmParams}`
          );
        } else {
          window.location.replace(
            `${window.location.origin}/#/Referrals${utmParams}`
          );
        }
      }
    }
  } catch (err) {
    console.error("handleLoginRedirect err", err);
  }
}

async function tapAffiliate_clickId() {
  let tap_affiliateId;
  try {
    const ref_info = getUtmDetails();
    if (ref_info.tap_ref) {
      let queryParam = {
        referral_code: ref_info.tap_ref,
      };
      if (ref_info.tap_source) {
        queryParam = {
          ...queryParam,
          source_id: ref_info.tap_source,
        };
      }
      const data = await dataProvider.custom_request(
        api.tap_affiliate,
        "GET",
        queryParam
      );
      if (data?.status === 200) {
        tap_affiliateId = data?.data?.id;
      }
    }
  } catch (err) {
    console.error("Signup tapaffiliate error", err);
  }

  return tap_affiliateId;
}

// Sync Google Calendar
export const isCalendarSynced = () => {
  return is_empty(window.localStorage.getItem("calendar_sync"));
};

const syncWithGoogle = async (selectionType) => {
  try {
    let auth = getAuthData();
    if (is_empty(auth)) return;
    let param = auth.social_info;
    param.min_calendar_bookings = selectionType;
    await dataProvider.updateWebpageDetails({
      updated_values: { social_info: param },
    });
    const sync_status = await dataProvider.handleGoogleSync();
    if (sync_status.status === 200) {
      return sync_status.message;
    } else return "Something went wrong";
  } catch (err) {
    console.error("syncWithGoogle err:", err);
    return !is_empty(err?.body?.message)
      ? err?.body?.message
      : "Something went wrong";
  }
};

async function syncWithGoogleService({
  serviceType,
  alreadySyncedCallback = () => {},
  return_url,
}) {
  if (!serviceType) return;
  try {
    // check if google service is already in sync
    const { data: validityData, status: validityStatus } =
      await dataProvider.custom_request(
        `${api.google_oauth.get_google_service_validity}/${serviceType}`
      );
    if (validityStatus !== 200)
      throw new Error("Couldn't sync! Please try again");

    // if already in sync then no need to initiate the sync
    if (validityData?.is_valid) {
      alreadySyncedCallback();
      return;
    }

    // initial google sync
    const { data: initiationData = {}, status: initiationStatus } =
      await dataProvider.custom_request(
        `${api.google_oauth.initiate_google_validation}/${serviceType}`,
        "POST",
        { return_url }
      );
    if (initiationStatus !== 200)
      throw new Error("Failed to sync! Please try again.");
    const { redirect_url } = initiationData;
    if (redirect_url) {
      window.open(redirect_url, "_self");
    }
  } catch (error) {
    console.error("sync with google service error: ", error);
  }
}

function setUserChecks(data) {
  sessionStorage.setItem(
    SESSION_STORAGE_KEYS.USER_CHECKS,
    JSON.stringify(data)
  );
}

function removeUserChecks() {
  sessionStorage.removeItem(SESSION_STORAGE_KEYS.USER_CHECKS);
}

/**
 * Fetches user checks from the backend or retrieves them from session storage if available.
 *
 * If checks are available in session storage, it will use those.
 *
 * @typedef {Object} UserChecks
 * @property {boolean} [key: string] - A boolean flag representing a specific user check.
 *
 * @returns {Promise<UserChecks>} A promise that resolves to an object containing user checks.
 * @throws {Error} Throws an error if the user checks cannot be fetched.
 */
async function getUserChecks({ forceCall } = {}) {
  try {
    let user_checks = {};

    let prev_user_checks = sessionStorage.getItem(
      SESSION_STORAGE_KEYS.USER_CHECKS
    );
    prev_user_checks = JSON.parse(prev_user_checks) || {};

    // if we have prev_user_checks we will pass that only.
    if (!is_empty(prev_user_checks) && !forceCall) {
      user_checks = prev_user_checks;
    } else {
      // when keys list is provided fresh api call should always be made
      user_checks = await dataProvider.custom_request(
        FEATURE_ACTIVATION_APIS.GET_FLAGS,
        apiMethods.GET
      );
      if (
        isRequestSuccessful(user_checks.status) &&
        !is_empty(user_checks.data)
      ) {
        // if prev_user_keys are there, mutate them to user_checks first
        user_checks = { ...prev_user_checks, ...user_checks.data };

        setUserChecks(user_checks);
      } else {
        logError({
          error: "Failed to fetch user checks",
          when: "calling getUserChecks",
          occuredAt: "src/utils/AuthUtil.js",
        });
      }
    }

    return user_checks;
  } catch (err) {
    console.error("fetchUserChecks error:", err);
    throw err;
  }
}

const isNewUser = () => {
  const authData = getAuthData();
  if (
    is_empty(authData) ||
    authData?.social_info?.is_auto_subdomain ||
    !authData?.sub_domain
  )
    return true;

  return false;
};

const hasCreatorPermissions = (menuItem) => {
  if (isInternationalTimezone()) return true;
  const permission = JSON.parse(
    sessionStorage.getItem("subscription_permission")
  );
  const subscriptionPermission = permission?.creator_section_mapping;
  for (let key in subscriptionPermission) {
    let menuObj = subscriptionPermission[key];
    for (let key in menuObj) {
      if (key === menuItem) {
        return menuObj[menuItem];
      }
    }
  }
};

const getCurrentActiveSubscriptionPlan = () => {
  const permission = JSON.parse(
    sessionStorage.getItem("subscription_permission")
  );
  return permission?.plan_details || {};
};

/**
 * Fetches the next steps based on provided keys and stores them in local storage.
 *
 * @param {Array<string>} [keys=[]] - An array of keys to be sent as request parameters.
 * @returns {Promise<void>} - A promise that resolves when the next steps are fetched and stored.
 */
const setNextSteps = (keys = []) => {
  dataProvider
    .custom_request(WHATS_NEXT_APIS.GET_PROGRESS, apiMethods.GET, {
      request_params: JSON.stringify(keys),
    })
    .then((steps) => {
      if (steps && isRequestSuccessful(steps.status)) {
        setNextStepsInLocalStorage({ data: steps.data });
      }
    });
};

export const setNextStepsInLocalStorage = ({ data }) => {
  const existingData = getWhatsNextInfo();
  setLocalStorageItem(
    LOCAL_STORAGE_KEYS.NEXT_STEPS,
    JSON.stringify({ ...existingData, ...data })
  );
};

const getGuestUsername = () => {
  // Since values have been set in both local storage and session storage for guest username for differen use cases
  // We first try to find the value in session storage if not found then we find in local storage
  return (
    window.sessionStorage.getItem(SessionStorageConstants.GUEST_USER_NAME) ||
    window.localStorage.getItem(local_storage_keys.GUEST_USER_NAME)
  );
};

export const getCreatorDomain = () => {
  return process.env.REACT_APP_PROJECT_DOMAIN;
};

export {
  getUserID,
  setUuid,
  setAuthData,
  getAuthData,
  onUserLogout,
  sendOtp,
  checkStaffLogin,
  hitAnalytic,
  setUtmDetails,
  getUtmDetails,
  checkEmptyUTM,
  setTokenUuid,
  getTokenUuid,
  setPermissions,
  handleLoginRedirect,
  tapAffiliate_clickId,
  getRolePowers,
  getUserCurrency,
  isInternationalCurrency,
  getUserCurrencySymbol,
  getUserTimezone,
  getUserCountryCode,
  isInternationalCountryCode,
  fetchAndUpdateAuth,
  isInternationalTimezone,
  syncWithGoogle,
  setUserChecks,
  getUserChecks,
  getPermissions,
  getSubscriptionPermission,
  getMvpleadData,
  fetchUserData,
  checkUserData,
  getUserCountry,
  getUserPhoneNumber,
  isNewUser,
  getUserLogoImage,
  hasCreatorPermissions,
  getCurrentActiveSubscriptionPlan,
  setSubscriptionPermission,
  setNextSteps,
  syncWithGoogleService,
  getGuestUsername,
  removeUserChecks,
  needToRefetchUserAuthDetails,
};
